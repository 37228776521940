import { 
  CheckOutlined, BarsOutlined, InsertRowAboveOutlined, HomeOutlined, TeamOutlined, TableOutlined, FileOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import FormattedMessage from 'components/util-components/IntlMessage'

// Vistas que siempre se mostrarán en el menú lateral
let dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'leftMenu.home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
}
]

// Listado de todas las vistas, desde donde se seleccionan las que serán mostradas en menú lateral
const dashBoardNavTreeRaw = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'leftMenu.home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'universalUsers',
  path: `${APP_PREFIX_PATH}/universal/users`,
  title: 'leftMenu.users',
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'casosBasic',
  path: `${APP_PREFIX_PATH}/learning/basicCases`,
  title: 'leftMenu.basicCases',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'boardsCRUD',
  path: `${APP_PREFIX_PATH}/learning/boards`,
  title: 'leftMenu.boards',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'boardsDetailCRUD',
  path: `${APP_PREFIX_PATH}/learning/boardsDetail`,
  title: 'leftMenu.boards',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'universalSessions',
  path: `${APP_PREFIX_PATH}/universal/sessions`,
  title: 'leftMenu.sessions',
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'sequenceSelectorPage',
  path: `${APP_PREFIX_PATH}/learning/sequenceSelector`,
  title: 'leftMenu.sequence',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
// ***************************** M O D U L O S **************************************
{
  key: 'EdificationVestimenta',
  path: `${APP_PREFIX_PATH}/apps/learning/Edification/Vestimenta`,
  title: 'leftMenu.vestimenta',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticVestimenta',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Vestimenta`,
  title: 'leftMenu.vestimenta',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticRiesgos',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Riesgos`,
  title: 'leftMenu.riesgos',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticPartesPiezas',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas`,
  title: 'leftMenu.partesypiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticFlujoTrabajo',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticPartesPiezas2024',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas2024`,
  title: 'leftMenu.partesypiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticFlujoTrabajo2024',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo2024`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticConstruccionLogistica2024',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica2024`,
  title: 'left.menu.construccionlogistica',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticConstruccionLogistica',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica`,
  title: 'left.menu.construccionlogistica',
  icon: InsertRowAboveOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true
},
{
  key: 'flujoTrabajoCocina',
  path: `${APP_PREFIX_PATH}/apps/learning/Gastronomia/FlujoTrabajo`,
  title: 'left.menu.flujotrabajococina',
  icon: InsertRowAboveOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true
},
// ************ SNA RIEGO ************
{
  key: 'partesPiezasRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/PartesPiezas`,
  title: 'left.menu.partesPiezas',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'montajeRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Montaje`,
  title: 'left.menu.montajeRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'flujoTrabajoRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/FlujoTrabajo`,
  title: 'left.menu.flujoTrabajoRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'bodegaRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Bodega`,
  title: 'left.menu.bodegaRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ SNA ALIMENTACION ************
{
  key: 'unityAlimentacionBodega',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Bodega`,
  title: 'left.menu.bodegaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'unityAlimentacionCocina',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Cocina`,
  title: 'left.menu.cocinaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'unityAlimentacionComedor',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Comedor`,
  title: 'left.menu.cocinaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'ModuloTest',
  path: `${APP_PREFIX_PATH}/apps/learning/Test`,
  title: 'ModuloTest',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ SQM QUIMICA *****************
{
  key: 'SQMFlujoTrabajo',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/QuimicaFlujos`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'SQMPartesPiezas',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/PartesPiezas`,
  title: 'left.menu.partesPiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
// ************ CONSTRUCCION DEMO ************
{
  key: 'construccionDemo',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionDemo`,
  title: 'left.menu.construccionDemo',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ CONSTRUCCION GENERADORA ************
{
  key: 'construccionGeneradora',
  path: `${APP_PREFIX_PATH}/apps/produccion/Generadora/Construccion`,
  title: 'left.menu.construccionGeneradora',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ REPORTE UNIVERSAL ************
{
  key: 'reporteExterno',
  path: `${APP_PREFIX_PATH}/externalReport`,
  title: 'left.menu.reporteExterno',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ LINKS ************
{
  key: 'manualGeneradoraConstruccion',
  path: '//imova.notion.site/Generadora-Metropolitana-cea479922cf7493dbeaf4a2fa56ca6f2',
  target:'_blank',
  title: 'left.menu.manualGeneradoraConstruccion',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'manualCFTTarapaca',
  path: '//imova.notion.site/Laboratorio-de-Log-stica-0ceac81ec4c04d1688eea58661a419f6',
  target:'_blank',
  title: 'left.menu.manualCFTTarapaca',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'manualSKCapacitacion',
  path: '//imova.notion.site/Laboratorio-de-Log-stica-SKIC-be0b31a76969419abd27195d2806501b',
  target:'_blank',
  title: 'left.menu.manualSKCapacitacion',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'manualUDPLogistica',
  path: '//imova.notion.site/Laboratorio-de-Log-stica-UDP-1ca3b6eb85ec4b5d89c6a2cf200bf7e1',
  target:'_blank',
  title: 'left.menu.manualUDPLogistica',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'manualCFTMauleLogistica',
  path: '//imova.notion.site/Laboratorio-de-Log-stica-CFT-Maule-10d4f4f2febc80419d08dc9f3d9aa652',
  target:'_blank',
  title: 'left.menu.manualCFTMauleLogistica',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},{
  key: 'manualCFTMauleRiego',
  path: '//imova.notion.site/Laboratorio-de-Riego-Tecnificado-CFT-Maule-10d4f4f2febc80bcb8a0e42c8a61678a',
  target:'_blank',
  title: 'left.menu.manualCFTMauleRiego',
  icon: FileOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ PACKS ************
{
  key: 'pack4Logistica',
  title: 'left.menu.logistica',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'logisticVestimenta',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Vestimenta`,
      title: 'leftMenu.vestimenta',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },    
    {
      key: 'logisticPartesPiezas',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas`,
      title: 'leftMenu.partesypiezas',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },
    {
      key: 'logisticFlujoTrabajo',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo`,
      title: 'leftMenu.flujoTrabajo',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },
    {
      key: 'logisticConstruccionLogistica',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica`,
      title: 'left.menu.construccionlogistica',
      icon: InsertRowAboveOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true
    }
  ]
},
{
  key: 'pack4Logistica2024',
  title: 'left.menu.logistica',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'logisticVestimenta',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Vestimenta`,
      title: 'leftMenu.vestimenta',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },    
    {
      key: 'logisticPartesPiezas2024',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas2024`,
      title: 'leftMenu.partesypiezas',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },
    {
      key: 'logisticFlujoTrabajo2024',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo2024`,
      title: 'leftMenu.flujoTrabajo',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    },
    {
      key: 'logisticConstruccionLogistica2024',
      path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica2024`,
      title: 'left.menu.construccionlogistica',
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: [],
      disabled: true,
    }
  ]
},
{
  key: 'packRiego',
  title: 'left.menu.packRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'partesPiezasRiego',
      path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/PartesPiezas`,
      title: 'left.menu.partesPiezas',
      icon: CheckOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'montajeRiego',
      path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Montaje`,
      title: 'left.menu.montajeRiego',
      icon: CheckOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'flujoTrabajoRiego',
      path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/FlujoTrabajo`,
      title: 'left.menu.flujoTrabajoRiego',
      icon: CheckOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
},
]

const addSubmenuToNavTree = (navTree, submenuData) => {
  return navTree.map(item => {
    if (submenuData[item.key]) {
      return {
        ...item,
        submenu: submenuData[item.key].map(submenuItem => ({
          ...submenuItem,
          submenu: submenuItem.submenu || []
        }))
      };
    }
    return item;
  });
};

export function setNavigationConfig () {
  // Lo primero: resetear dashboardNavTree 
  dashBoardNavTree = [{
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'leftMenu.home',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: []
  }
  ]

  // Luego, obtener lista de menú a cargar
  if(window.localStorage.PRIMARY_DATA !== undefined){
    const leftMenuArray = (JSON.parse(window.localStorage.PRIMARY_DATA).LayoutData.LeftMenu)
    if(leftMenuArray !== undefined){
      // Recorrer dashBoardNavTreeRaw para ir cargando los menú a dashBoardNavTree
      dashBoardNavTreeRaw.map((item) => {
        if (leftMenuArray.includes(item.key)) dashBoardNavTree.push(item)
        return true
      })
    }
  }

  // Aplicar submenús provenientes de la DB secundaria /Usuaurios
  
  // Obtener submenús
  const allSubmenu = JSON.parse(localStorage.getItem("SECONDARY_SUBMENU"))
  // Aplicarlos al navTree
  if(allSubmenu !== null)
    dashBoardNavTree = addSubmenuToNavTree (dashBoardNavTree, allSubmenu)
  
return dashBoardNavTree}

const navigationConfig = setNavigationConfig()

export default navigationConfig;